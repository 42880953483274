import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import noodles from "../../images/noodles.svg";
import { Link as ScrollLink } from "react-scroll";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const pageLocation =
    typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <header
      className="bg-yellow-600 sticky top-0 w-full"
      style={{ zIndex: 2000 }}
    >
      <div className="flex flex-wrap items-center justify-between p-4 mx-auto md:px-8">
        <Link to="/">
          <div className="flex">
            <img src={noodles} alt="Pho Phi Logo" className="h-8 mr-3" />

            <h1 className="flex items-center text-white no-underline">
              {/* <svg
              className="w-8 h-8 mr-2 fill-current"
              height="54"
              viewBox="0 0 54 54"
              width="54"
              xmlns="http://www.w3.org/2000/svg"
              >
              <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
            </svg> */}

              <span className="text-xl font-bold tracking-tight">
                {site.siteMetadata.title}
              </span>
            </h1>
          </div>
        </Link>

        <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        {pageLocation === "/" ? (
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:items-center w-full md:w-auto`}
          >
            <ScrollLink
              activeClass="underline font-bold"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
              to="home"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Home
            </ScrollLink>
            <ScrollLink
              activeClass="underline font-bold"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
              to="info"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Store Info
            </ScrollLink>
            <ScrollLink
              activeClass="underline font-bold"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
              to="menu"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Menu
            </ScrollLink>
            <ScrollLink
              activeClass="underline font-bold"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
              to="featuredItems"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Featured Items
            </ScrollLink>
            <ScrollLink
              activeClass="underline font-bold"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
              to="posts"
              spy={true}
              smooth={true}
              duration={1000}
            >
              Posts
            </ScrollLink>

            <Link
              to="/jobs"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
            >
              Jobs
            </Link>
          </nav>
        ) : (
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:items-center w-full md:w-auto`}
          >
            <Link
              to="/"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
            >
              Home
            </Link>

            <Link
              to="/jobs"
              className="block mt-4 text-white font-semibold cursor-pointer md:inline-block md:mt-0 md:ml-3 text-sm hover:text-yellow-100 transition duration-100"
            >
              Jobs
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
